// Demain collection
export const DemandCollectionBase = '/bsri/demand-distribution/demand-collection'
export const FarmerInfo = DemandCollectionBase + '/farmer-info'
export const demandCollectionList = DemandCollectionBase + '/list'
export const demandCollectionStore = DemandCollectionBase + '/store'
export const demandCollectionUpdate = DemandCollectionBase + '/update'
export const demandCollectionFinalSave = DemandCollectionBase + '/finalsave'
export const designationWiseUser = DemandCollectionBase + '/get-user'
export const demandCollectionFor = DemandCollectionBase + '/forward'
export const demandCollectionRequestList = DemandCollectionBase + '/request-list'
export const demandCollApproveReject = DemandCollectionBase + '/approve-reject'

// Checklist wise info verification
export const checklistInfoVerificationBase = '/bsri/demand-distribution/checklist-info-verification'
export const farmerList = checklistInfoVerificationBase + '/list'
export const itemDataShow = checklistInfoVerificationBase + '/show'
export const checklistVerificaitonUpdate = checklistInfoVerificationBase + '/update'

// Subsidy Requisite List Verification
export const subsidyRequisiteListVerificationBase = '/bsri/demand-distribution/subsidy-requisite-list-verification'
export const centerWiseFarmerList = subsidyRequisiteListVerificationBase + '/list'
export const farmerDataList = subsidyRequisiteListVerificationBase + '/farmer-list'
export const farmerDataShowList = subsidyRequisiteListVerificationBase + '/farmer-list-show'
export const farmerVerifiedStore = subsidyRequisiteListVerificationBase + '/farmer-verified'

// Field Level Inspection Api Routes...
export const SubsidyReqVerfiedList = '/bsri/demand-distribution/field-level-inspection/list'
export const fieldLevelInspectionStore = '/bsri/demand-distribution/field-level-inspection/store'
export const fieldLevelInspectionUpdate = '/bsri/demand-distribution/field-level-inspection/update'
export const fieldLevelInspeFarmerList = '/bsri/demand-distribution/field-level-inspection/verfied-farmer-list'

// Approval Subsidized farmer Api Routes...
export const FieldInspectionList = '/bsri/demand-distribution/approval-subsidized-farmer-list/subsidized-farmer-list'
export const inspectedFarList = '/bsri/demand-distribution/inspected-farmer/inspected-farmer-list'
export const ApprovalSubsidizedFarmerStore = '/bsri/demand-distribution/approval-subsidized-farmer/store'
export const ApprovalSubsidizedFarmerUpdate = '/bsri/demand-distribution/approval-subsidized-farmer/update'

// Consent Fund Clearance Api route
export const consentFundClearanceBase = '/bsri/demand-distribution/consent-fund-clearance-list'
export const fundClearanceList = consentFundClearanceBase + '/list'
export const fundClearanceStore = consentFundClearanceBase + '/store'
export const fundClearanceUpdate = consentFundClearanceBase + '/update'

// Approval Subsidized farmer Api Routes...
export const summarySheetList = '/bsri/demand-distribution/mill-subsidiary-verifications/list'
export const summarySheetStore = '/bsri/demand-distribution/mill-subsidiary-verifications/store'
export const summarySheetDetails = '/bsri/demand-distribution/mill-subsidiary-verifications/subzone-office-info'

// SubSidy Proposal Approve Api Routes...
export const subSidyProApprList = '/bsri/demand-distribution/subsidy-proposal_approval/mill-subsidy-verification-list'
export const subSidyProApprDetails = '/bsri/demand-distribution/subsidy-proposal_approval/mill-info'
export const subSidyProApprStore = '/bsri/demand-distribution/subsidy-proposal_approval/store'

// SubSidy Proposal Approve List Api Routes...
export const subSidyProApprovalList = '/bsri/demand-distribution/subsidy-proposal_approval/list'

// Subsidized Approval Base
export const SubsidizedApprovalBase = '/bsri/demand-distribution/distribution-preparation'
export const SubsidizedApprovalList = SubsidizedApprovalBase + '/list'
export const distributionListPreparationStore = SubsidizedApprovalBase + '/store'

// SubSidy Proposal Approve List Api Routes...
export const distributionRegistrarList = '/bsri/demand-distribution/distribution-registrar/list'
export const distributionRegistrarStore = '/bsri/demand-distribution/distribution-registrar/store'
export const distributionRegistrarfarmers = '/bsri/demand-distribution/distribution-registrar/farmers'

// Distribution Approval Api Routes...
export const distrubutionApprovalBase = '/bsri/demand-distribution/distribution-list-approval'
export const distributionPrepListApi = distrubutionApprovalBase + '/list'
export const distributionPrepListStore = distrubutionApprovalBase + '/store'

// Subsidy-requisition-and-approval Api Routes...
export const subReqApprove = '/bsri/demand-distribution/subsidy-requisition-and-approval'
export const subsidyRequisitionAndApprovalListApi = subReqApprove + '/list'
// export const subsidyRequisitionAndApprovalEdit = subReqApprove + '/edit'
export const subsidyRequisitionAndApprovalMillWiseSubAmount = subReqApprove + '/mill-wise-total-subsidy-amount'
export const subsizeAlloList = subReqApprove + '/allocation-list'
export const subsizeAlloForwardSave = subReqApprove + '/forward-save'
export const subsizeAlloForward = subReqApprove + '/forward'
export const subsizeAlloReqList = subReqApprove + '/request-list'
export const subsizeAlloApprove = subReqApprove + '/approve'
export const subsizeAlloStatusUpdate = subReqApprove + '/status-update'
// fund Disburse Api Routes...
export const subsidyFundDisburseList = '/bsri/demand-distribution/fund-disbursement/list'
export const fundDisburseStore = '/bsri/demand-distribution/fund-disbursement/store'
export const fundDisburseShow = '/bsri/demand-distribution/fund-disbursement/show'

// fund Received Api Routes
export const fundReceivedList = '/bsri/demand-distribution/fund-received/list'
export const fundReceivedStore = '/bsri/demand-distribution/fund-received/store'
export const fundReceivedShow = '/bsri/demand-distribution/fund-received/show'
// Subsidize far approval Api Routes...
export const subFarApprovalPendingList = '/bsri/demand-distribution/subsidized-far-approvals/pendingList'
export const subFarApprovalList = '/bsri/demand-distribution/subsidized-far-approvals/approvedList'
export const subFarApprovalDraftStore = '/bsri/demand-distribution/subsidized-far-approvals/draftStore'
export const subFarApprovalStore = '/bsri/demand-distribution/subsidized-far-approvals/forwardRequest'
export const subFarDemandDetails = '/bsri/demand-distribution/subsidized-far-approvals/demand-details'
export const subFarApproveDetails = '/bsri/demand-distribution/subsidized-far-approvals/approved-details'
// nothi route
export const pendingNothiSubsidyRequisitionAndApprovalList = '/bsri/nothi-subsidy-requisition-and-approval/sent-nothi'
