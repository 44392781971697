<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                                            <b-row>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <table class="table table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <th>{{$t('bsri_demandDIS.fiscal_year')}}</th>
                                                                <td>
                                                                    {{ $i18n.locale === 'bn' ? item.fiscal_year_bn : item.fiscal_year }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>{{$t('bsri_demandDIS.mill_name')}}</th>
                                                                <td>
                                                                    {{ $i18n.locale === 'bn' ? item.mill_type_name_bn : item.mill_type_name }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>{{$t('bsri_demandDIS.subzone_office_name')}}</th>
                                                                <td>
                                                                    {{ $i18n.locale === 'bn' ? item.subzone_office_name_bn : item.subzone_office_name }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>{{$t('bsri_demandDIS.center_name')}}</th>
                                                                <td>
                                                                    {{ $i18n.locale === 'bn' ? item.center_bn : item.center }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>{{$t('bsri_demandDIS.unit_no')}}</th>
                                                                <td>
                                                                    {{ $i18n.locale === 'bn' ? item.unit_bn : item.unit }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>{{$t('bsri_demandDIS.total_farmers')}}</th>
                                                                <td>
                                                                    {{ $n(item.total_farmers, { useGrouping: false }) }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>{{$t('bsri_demandDIS.total_subsidized_land')}}</th>
                                                                <td>
                                                                    {{ $n(item.total_subsidized_land, { useGrouping: false }) }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <template>
                                                        <div style="font-size:18px; background-color: #66CC99;" class="mb-3 p-1">
                                                            <h5 class="text-white"> {{ $t('bsri_demandDIS.farmers_list') }}</h5>
                                                        </div>
                                                    </template>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <table class="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>{{$t('bsri_demandDIS.sl')}}</th>
                                                                <th>{{$t('bsri_demandDIS.farmer_name')}}</th>
                                                                <th>{{$t('bsri_demandDIS.farmer_father_name')}}</th>
                                                                <th>{{$t('bsri_demandDIS.farmer_nid_number')}}</th>
                                                                <th>{{$t('bsri_demandDIS.mobile_number')}}</th>
                                                                <th>{{$t('bsri_demandDIS.method_of_cultivation')}}</th>
                                                                <th>{{$t('bsri_demandDIS.total_land_quantity')}}</th>
                                                                <th>{{$t('bsri_demandDIS.proposed_amount')}}</th>
                                                                <th>{{$t('bsri_demandDIS.distribution_preparation_selection')}}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(farmerInfo, index) in distributionPreparation.farmer_list" :key="index">
                                                                <td>
                                                                    {{ $n(index + 1) }}
                                                                </td>
                                                                <td>
                                                                     {{ $i18n.locale === 'bn' ? farmerInfo.name_bn : farmerInfo.name }}
                                                                </td>
                                                                <td>
                                                                     {{ $i18n.locale === 'bn' ? farmerInfo.father_name_bn : farmerInfo.father_name }}
                                                                </td>
                                                                <td>
                                                                    {{ $n(farmerInfo.nid, {useGrouping:false}) }}
                                                                </td>
                                                                <td>
                                                                    {{ '0' + $n(farmerInfo.mobile_no, {useGrouping:false}) }}
                                                                </td>
                                                                <td>
                                                                    {{ $i18n.locale === 'bn' ? farmerInfo.cultivation_method_name_bn : farmerInfo.cultivation_method_name }}
                                                                </td>
                                                                <td>
                                                                    {{ $n(farmerInfo.land_qty, {useGrouping:false} )}}
                                                                </td>
                                                                <td>
                                                                     {{ $n(farmerInfo.cultivation_per_acr_amount, {useGrouping:false} )}}
                                                                </td>
                                                                <td>
                                                                     <input type='checkbox' v-model='farmerInfo.is_checked' checked="checked"/>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <ValidationProvider name="Comments (En)" vid="comments" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="2"
                                                            label-for="comments"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('bsri_demandDIS.comments_en')}}
                                                        </template>
                                                            <b-form-textarea
                                                            id="description"
                                                            v-model="distributionPreparation.comment"
                                                            rows="1"
                                                            max-rows="2"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-textarea>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <ValidationProvider name="Comments (Bn)" vid="comments_bn" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="2"
                                                            label-for="comments_bn"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('bsri_demandDIS.comments_bn')}}
                                                        </template>
                                                            <b-form-textarea
                                                            id="description"
                                                            v-model="distributionPreparation.comment_bn"
                                                            rows="1"
                                                            max-rows="2"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-textarea>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <p>
                                                        <label for='terms'>
                                                        <input id='terms' type='checkbox' v-model='terms' /> <span class="text-danger"> {{ $t('bsri_demandDIS.accept_terms') }}</span>
                                                        </label>
                                                    </p>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col>
                                                    <div class="col text-right">
                                                        <b-button type="submit" variant="primary" class="mr-2"  :disabled='isDisabled'>{{ saveBtnName }}</b-button>
                                                        &nbsp;
                                                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </b-form>
                                    </ValidationObserver>
                                </b-col>
                                <pre>
                                    {{ item }}
                                </pre>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { ValidationObserver } from 'vee-validate'
import { distributionListPreparationStore } from '../../api/routes'

export default {
    props: ['item'],
    components: {
        ValidationObserver
    },
    data () {
        return {
            loading: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            onlyCreate: true,
            distributionPreparation: {
                sub_approval_id: 0,
                fiscal_year_id: '0',
                mill_info_id: '0',
                subzone_office_id: '0',
                center_id: '0',
                unit_id: '0',
                farmer_list: [],
                comment: '',
                comment_bn: ''
            },
            terms: false,
            customItem: this.item
        }
    },
    created () {
        if (this.item) {
            this.getEditingData(this.item)
        }
    },
    watch: {
    },
    mounted () {
        core.index()
    },
    computed: {
        locale: function () {
            return this.$i18n.locale
        },
        isDisabled: function () {
            return !this.terms
        }
    },
    methods: {
        getEditingData (item) {
            this.distributionPreparation.sub_approval_id = item.id
            this.distributionPreparation.fiscal_year_id = item.fiscal_year_id
            this.distributionPreparation.mill_info_id = item.mill_info_id
            this.distributionPreparation.subzone_office_id = item.subzone_office_id
            this.distributionPreparation.center_id = item.center_id
            this.distributionPreparation.unit_id = item.unit_id
            item.details.filter(detail => {
                const cultivationMethodObj = this.$store.state.incentiveGrant.commonObj.cultivationMethodList.find(item => item.value === detail.demand_collection.cultivation_id)
                const cultivationPolicieMethodObj = this.$store.state.incentiveGrant.commonObj.cultivationPolicy.find(item => item.method_cultivation_id === detail.demand_collection.cultivation_id)
                const obj = {
                    far_general_info_id: detail.far_general_infos.id,
                    demand_collection_id: detail.demand_collection_id,
                    name: detail.far_general_infos.name,
                    name_bn: detail.far_general_infos.name_bn,
                    father_name: detail.far_general_infos.father_name,
                    father_name_bn: detail.far_general_infos.father_name_bn,
                    nid: detail.far_general_infos.nid_no,
                    mobile_no: detail.far_general_infos.mobile_no,
                    cultivation_method_name: typeof cultivationMethodObj !== 'undefined' ? cultivationMethodObj.text_en : '',
                    cultivation_method_name_bn: typeof cultivationMethodObj !== 'undefined' ? cultivationMethodObj.text_bn : '',
                    cultivation_per_acr_amount: typeof cultivationPolicieMethodObj !== 'undefined' ? cultivationPolicieMethodObj.per_acr_amount : '',
                    land_qty: detail.demand_collection.total_land,
                    is_checked: true
                }
                this.distributionPreparation.farmer_list.push(obj)
            })
        },
        async saveData () {
            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }
            result = await RestApi.postData(incentiveGrantServiceBaseUrl, distributionListPreparationStore, this.distributionPreparation)
            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)
            if (result.success) {
                this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                if (result.errors) {
                    this.$refs.form.setErrors(result.errors)
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: result.message,
                        color: '#D6E09B'
                    })
                }
            }
             this.loading = false
        }
    }
}
</script>
